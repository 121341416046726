import {React, useState} from 'react';
import ReactDOM from "react-dom";

const Baixa = () => {

    const [inputState, setInputState] = useState({});
    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState(null);
    const [criticalError, setCriticalError] = useState(false);

    const onInputChange = (props) => {
        let {name, value, checked} = props.target ? props.target : props;
        if (props.target && props.target.type === "checkbox") {
            value = !!checked;
        }
        if (props.target && props.target.type === 'file') {
            value = props.target.files;
        }
        setInputState({...inputState, [name]: value});
    }

    const hasError = (error) => {
        return !!errors[error];
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        Object.keys(inputState).forEach((key) => {
            formData.append(key, inputState[key]);
        });

        axios.post('/formulari-baixa', formData)
            .then((response) => {
                if (response.data.message) {
                    setMessage(response.data.message);
                }
            })
            .catch((error) => {
                if (error.response.data) {
                    if (error.response.status === 500) {
                        setCriticalError(true);
                    } else {
                        setErrors(error.response.data.errors);
                    }
                }
            })

    }

    return (
        <div>
            {message && (
                <div className="alert alert-success">
                    {message}
                </div>
            )}
            {!message && (
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Nom i llinatges <sup>*</sup></label>
                        <input type={"text"} name="name" placeholder="Nom i llinatges" className={
                            hasError("name")
                                ? "form-control is-invalid"
                                : "form-control"
                        } required={true} onChange={onInputChange} value={inputState.name}/>
                        <div className={
                            hasError("name") ? "invalid-feedback" : "d-none"
                        }>
                            {errors['name']}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Matrícula que es vol donar de baixa <sup>*</sup></label>
                        <input type={"text"} name="matricula" placeholder="Matrícula" className={
                            hasError("matricula")
                                ? "form-control is-invalid"
                                : "form-control"
                        } required={true} onChange={onInputChange} value={inputState.matricula}/>
                        <div className={
                            hasError("matricula") ? "invalid-feedback" : "d-none"
                        }>
                            {errors['matricula']}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Correu electrònic <sup>*</sup></label>
                        <input type={"email"} name="email" placeholder="Correu electrònic" className={
                            hasError("email")
                                ? "form-control is-invalid"
                                : "form-control"
                        } required={true} onChange={onInputChange} value={inputState.email}/>
                        <div className={
                            hasError("email") ? "invalid-feedback" : "d-none"
                        }>
                            {errors['email']}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="nif">DNI/NIF <sup>*</sup></label>
                        <input type={"nif"} name="nif" placeholder="DNI/NIF" className={
                            hasError("nif")
                                ? "form-control is-invalid"
                                : "form-control"
                        } required={true} onChange={onInputChange} value={inputState.nif}/>
                        <div className={
                            hasError("nif") ? "invalid-feedback" : "d-none"
                        }>
                            {errors['nif']}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="comments">Motius</label>
                        <textarea name="comments" placeholder="Motius..." className={
                            hasError("comments")
                                ? "form-control is-invalid"
                                : "form-control"
                        } onChange={onInputChange} value={inputState.comments} required={true} />
                        <div className={
                            hasError("comments") ? "invalid-feedback" : "d-none"
                        }>
                            {errors['comments']}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-check">
                            <input id="accept-lopd" className="form-check-input" name="acceptLopd" type="checkbox" required={true} checked={inputState.acceptLopd} onChange={onInputChange}/>
                            <label className="form-check-label" htmlFor="accept-lopd">Consent l'ús de les meves
                                dades per a les finalitats indicades a
                                la <a href="https://www.mobilitatmanacor.cat/proteccio-de-dades/" target="_blank">Politica
                                    de privacitat</a> <sup>*</sup></label>
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary" disabled={inputState.name == '' || inputState.email == '' || inputState.matricula == '' || !inputState.acceptLopd}>
                            Envia
                        </button>
                    </div>
                </form>
            )}
            {criticalError && (
                <div className="col">
                    <div className="alert alert-danger">
                        Hem trobat un error quan hem generat la teva autorització. Prova una altra vegada o posa't en contacte
                        amb nosaltres <a href="https://www.mobilitatmanacor.cat/formulari-de-contacte/" target="_blank">aqui</a>.
                    </div>
                </div>
            )}
        </div>
    )
}

export default Baixa;

if (document.getElementById('react-baixa-form')) {
    ReactDOM.render(<Baixa/>, document.getElementById('react-baixa-form'));
}
