import {React, useState} from 'react';
import ReactDOM from "react-dom";
import AccesForm from "./AccesForm";

const Access = () => {

    const [matricula, setMatricula] = useState('');
    const [tipus, setTipus] = useState(4);
    const [tipusAll, setTipusAll] = useState([]);
    const [motiusAll, setMotiusAll] = useState([]);
    const [errors, setErrors] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);

    const handleMatriculaState = (el) => {
        setMatricula(el.target.value);
    }

    const hasError = (error) => {
        return !!errors[error];
    }

    const handleSubmit = () => {

        let formData = new FormData();
        formData.append('matricula', matricula);

        let captchaResponse = grecaptcha.getResponse();
        if (!captchaResponse) {
            setErrors({'recaptcha-token': 'Verifica que no ets un robot'});
            return false;
        }
        formData.append('recaptcha-token', captchaResponse);


        axios.post('/acces', formData, {}).then((response) => {
            setErrors({});
            grecaptcha.reset();
            if (response.data.exists) {
                setPdfUrl(response.data.pdfUrl);
                setMatricula('');
            } else {
                setTipus(response.data.tipus_acces);
                setTipusAll(response.data.tipus_all);
                setMotiusAll(response.data.motius_all);
                setShowForm(true);
            }

        }).catch((error) => {
            grecaptcha.reset();
            if (error.response.data) {
                setErrors(error.response.data.errors);
            }
        })
    }

    return (
        <div>
            {!showForm &&
            <div className="row align-items-center justify-content-center">
                <div className="col-md-8">
                    <h2>Sol·licita una autorització</h2>
                    <div className="form-group">
                        <label htmlFor="matricula">Introduïu la matrícula del vehicle.</label>
                        <input type="text"
                               className={
                                   hasError("matricula")
                                       ? "form-control is-invalid"
                                       : "form-control"
                               }
                               name="matricula"
                               id="matricula"
                               placeholder="Introduïu la matrícula del vehicle"
                               value={matricula}
                               onChange={handleMatriculaState}/>
                        <div className={
                            hasError("matricula") ? "invalid-feedback" : "d-none"
                        }>
                            {errors['matricula']}
                        </div>
                    </div>

                    <div className={
                        hasError("recaptcha-token") ? "d-block alert alert-danger" : "d-none"
                    }>
                        {errors['recaptcha-token']}
                    </div>

                    <div className="g-recaptcha form-group"
                         data-sitekey="6Lfm228aAAAAAIxHFs-D_m8uczX6Pqn3FjUl-GV-"
                         data-action="homepage"></div>
                    <div className="form-group">
                        <button type="button"
                                className="btn btn-primary"
                                disabled={matricula === ''}
                                onClick={handleSubmit}>Continua
                        </button>
                    </div>
                </div>
            </div>
            }
            {pdfUrl && !showForm && (
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-8">
                        <div className="w-100 mb-2">
                            <a className="btn btn-block btn-lg btn-primary" target="_blank" href={pdfUrl}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     style={{fill: '#ffffff'}}
                                     className="mr-2"
                                     enable-background="new 0 0 24 24"
                                     height="24"
                                     viewBox="0 0 24 24"
                                     width="24">
                                    <g>
                                        <rect fill="none" height="24" width="24"/>
                                    </g>
                                    <g>
                                        <path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z"/>
                                    </g>
                                </svg>
                                Descarrega l'autorització
                            </a>
                        </div>
                        <div className="w-100">
                            <a href="/" className="btn btn-outline-secondary btn-block">Genera una altre
                                autorització</a>
                        </div>
                    </div>
                </div>
            )}
            {showForm && tipus && tipusAll &&
            <AccesForm tipus_inicial={tipus} tipusAll={tipusAll} motiusAll={motiusAll} matricula={matricula}/>
            }
        </div>);
}

export default Access;

if (document.getElementById('react-acces-form')) {
    ReactDOM.render(<Access/>, document.getElementById('react-acces-form'));
}
