import {React, useState} from 'react';

const Confirm = ({response}) => {
    return (
        <div className="col-md-6">
            {response.missatge && <div className="alert alert-success text-center">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                    <path d="M0 0h24v24H0V0z" fill="none"/>
                    <path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z"/>
                </svg>
                <span className="ml-2">{response.missatge}</span>
            </div>
            }
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="w-100 mb-2">
                    {response.estat === 'generat' && response.pdfUrl && (
                        <a className="btn btn-block btn-primary" target="_blank" href={response.pdfUrl}>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{fill: '#ffffff'}} className="mr-2" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
                                <g>
                                    <rect fill="none" height="24" width="24"/>
                                </g>
                                <g>
                                    <path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z"/>
                                </g>
                            </svg>
                            Descarrega l'autorització
                        </a>
                    )}
                </div>
                <div className="w-100">
                    <a href="/" className="btn btn-outline-secondary btn-block">Genera una altre
                        autorització</a>
                </div>
            </div>
        </div>
    )
}

export default Confirm;