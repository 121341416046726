import {React, useState} from 'react';
import Confirm from "./Confirm";

const initialInputState = {
    name: '',
    dni: '',
    email: '',
    phone: '',
    acceptRules: false,
    acceptLopd: false,
    acceptSendToEmail: true,
    files: [],
    date: new Date().toISOString().slice(0, 16),
}

const AccesForm = ({tipus_inicial, tipusAll, matricula, motiusAll = []}) => {

    const [tipus, setTipus] = useState(tipus_inicial);
    const [generated, setGenerated] = useState(false);
    const [inputState, setInputState] = useState(initialInputState);
    const [errors, setErrors] = useState([]);
    const [postResponse, setPostResponse] = useState({});
    const [loading, setLoading] = useState(false);
    const [criticalError, setCriticalError] = useState(false);

    const onInputChange = (props) => {
        let {name, value, checked} = props.target ? props.target : props;
        if (props.target && props.target.type === "checkbox") {
            value = !!checked;
        }
        if (props.target && props.target.type === 'file') {
            value = props.target.files;
        }
        setInputState({...inputState, [name]: value});
    }

    const hasError = (error) => {
        return !!errors[error];
    }

    const handleChangeTipus = (id) => {
        setTipus(id);
    }

    const AccesTipusButtons = () => {

        return tipusAll.map((el, key) => {
            if (el.id !== 1) {
                return (
                    <button key={key} className={`btn ${tipus === el.id ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => handleChangeTipus(el.id)}>{el.name}
                    </button>
                )
            }
            return null;
        });

    }

    const AccesTipusDescription = () => {
        const description = tipusAll.filter(x => x.id === tipus);
        if (!description.length) {
            return <div></div>;
        }
        return (
            <p className="m-0 p-0" dangerouslySetInnerHTML={{__html: description[0].description}}/>
        )
    }

    const AccesTipusImage = () => {
        const object = tipusAll.filter(x => x.id === tipus);
        if (!object.length) {
            return <div></div>;
        }
        return (
            <img src={"images/icon_" + object[0].machine_name + '.png'} alt="" style={{height: "250px"}}/>
        )
    }

    const IsTipusActive = () => {
        const active = tipusAll.filter(x => x.id === tipus);
        return !!active[0].status;
    }

    const AccesTipusDocs = () => {
        const documentacio = tipusAll.filter(x => x.id === tipus);
        if (!documentacio.length) {
            return <div></div>;
        }
        return (
            <div className="mt-3">
                <h4>Documentació a presentar</h4>
                <div dangerouslySetInnerHTML={{__html: documentacio[0].documentacio}}/>
                {documentacio[0].docs && (documentacio[0].docs.length > 0) && (
                    <div className="my-3">
                        <h4>Models</h4>
                        <p><small>Pots descarregar els models, omplir-los i pujar-los al camp "Documentació
                            necessària"</small></p>
                        <div className="btn-block btn-group-vertical">
                            {documentacio[0].docs.map((doc) => {
                                return (
                                    <a href={'/storage/' + doc.file} key={doc.id} className="btn btn-outline-info btn-block" target="_blank">
                                        {doc.description}
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                )}

            </div>
        )
    }


    const canGenerateAcces = () => {
        return !(inputState.name !== '' && inputState.dni !== '' && inputState.email !== '' && inputState.acceptLopd && inputState.acceptRules && !loading);
    }

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('matricula', matricula);
        formData.append('tipus', tipus);

        Object.keys(inputState).forEach((key) => {
            formData.append(key, inputState[key]);
        })

        if (inputState.files.length) {
            if (inputState.files.length >= 5) {
                setErrors({"files.0": "Només es poden pujar 5 fitxers"});
                return false;
            }
            for (var i = 0; i < inputState.files.length; i++) {
                const file = inputState.files[i];
                let fileSize = Math.round(file.size / 1024);
                if (fileSize >= 5000) {
                    setErrors({"files.0": "Un dels fitxers supera els 5MB"});
                    return false;
                }
                formData.append('files[' + i + ']', file);
            }
        }

        setLoading(true);
        setCriticalError(false);
        setErrors([]);
        axios.post('/acces/formulari', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                setPostResponse(response.data);
                setLoading(false);
                setGenerated(true);
            })
            .catch((error) => {
                setLoading(false);
                if (error.response.data) {
                    if (error.response.status === 500 && error.response.data.error) {
                        setCriticalError(true);
                    } else {
                        setErrors(error.response.data.errors);
                    }
                }
            })
    }


    return (
        <div className={"acces-form-react row justify-content-center"}>
            {!generated && (
                <div className="col-md-8">
                    {tipus !== 1 &&
                    <div className="mb-2">
                        <h3>Seleccionau un tipus d'autorització</h3>
                        <div className="btn-group btn-block pb-3">
                            <AccesTipusButtons/>
                        </div>
                        <div>
                            <AccesTipusDescription/>
                            <small>Segons l’Ordenança reguladora sobre l’ús de les vies de la Zona de Prioritat
                                Residencial (BOIB núm. 10, de 23/01/2021)</small>
                        </div>
                    </div>
                    }

                    {IsTipusActive() && (
                        <div className="py-2">
                            <h2>Dades del sol·licitant</h2>

                            <div className="form-group">
                                <label htmlFor="name">Nom i llinatges <sup>*</sup></label>
                                <input type={"text"} name="name" placeholder="Nom i llinatges" className={
                                    hasError("name")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                } required={true} onChange={onInputChange} value={inputState.name}/>
                                <div className={
                                    hasError("name") ? "invalid-feedback" : "d-none"
                                }>
                                    {errors['name']}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="dni">DNI <sup>*</sup></label>
                                <input type={"text"} name="dni" placeholder="DNI" className={
                                    hasError("dni")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                } required={true} onChange={onInputChange} maxLength="10" minLength="9" pattern="[0-9]{8}[A-Za-z]{1}" value={inputState.dni}/>
                                <div className={
                                    hasError("dni") ? "invalid-feedback" : "d-none"
                                }>
                                    {errors['dni']}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Correu electrònic <sup>*</sup></label>
                                <input type={"email"} name="email" placeholder="Correu electrònic" className={
                                    hasError("email")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                } required={true} onChange={onInputChange} value={inputState.email}/>
                                <div className={
                                    hasError("email") ? "invalid-feedback" : "d-none"
                                }>
                                    {errors['email']}
                                </div>
                            </div>
                            {[1, 4].includes(tipus) &&
                            (
                                <div className="form-group">
                                    <div className="form-check">
                                        <input id="acceptSendToEmail" className="form-check-input" name="acceptSendToEmail" type="checkbox" required={true} checked={inputState.acceptSendToEmail} onChange={onInputChange} value="1"/>
                                        <label className="form-check-label" htmlFor="acceptSendToEmail">Vull rebre
                                            l’autorització per correu electrònic (també es pot descarregar a
                                            continuació)</label>
                                    </div>
                                </div>
                            )
                            }
                            <div className="form-group">
                                <label htmlFor="phone">Telèfon</label>
                                <input type={"text"} name="phone" placeholder="Telèfon" className={
                                    hasError("phone")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                } required={true} onChange={onInputChange} value={inputState.phone}/>
                                <div className={
                                    hasError("phone") ? "invalid-feedback" : "d-none"
                                }>
                                    {errors['phone']}
                                </div>
                            </div>
                            {
                                [2, 3, 5, 7].includes(tipus) &&
                                (<>
                                        <div className="form-group">
                                            <label htmlFor="address">Direcció (Carrer i número)</label>
                                            <input type={"text"} name="address" placeholder="Direcció (Carrer i número)" onChange={onInputChange} className={
                                                hasError("address")
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            } required/>
                                            <div className={
                                                hasError("address") ? "invalid-feedback" : "d-none"
                                            }>
                                                {errors['address']}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <h3>Documentació necessària</h3>
                                            <input type={"file"} name="files" onChange={onInputChange} className={
                                                hasError("files.0")
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            } accept="image/*, .pdf" capture="environment" multiple/>
                                            <div className="text-mute form-text">Fitxers de tipus imatge o PDF que no
                                                superin els 5MB cada un
                                            </div>
                                            <div className={
                                                hasError("files.0") ? "invalid-feedback" : "d-none"
                                            }>
                                                {errors['files.0']}
                                            </div>
                                            <div className={
                                                hasError("files") ? "alert alert-danger" : "d-none"
                                            }>
                                                {errors['files']}
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                tipus === 4 &&
                                (<>
                                        <div className="form-group">
                                            <label>Dia que vull accedir a la ZPR</label>
                                            <input type={"date"} name="date" className={
                                                hasError("date")
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            } onChange={onInputChange} value={inputState.date} min={inputState.date}/>
                                            <div className={
                                                hasError("date") ? "invalid-feedback" : "d-none"
                                            }>
                                                {errors['date']}
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Motius <sup>*</sup></label>
                                            <select name="motius" className={
                                                hasError("motius")
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            } onChange={onInputChange} defaultValue={inputState.motius ? inputState.motius : ""}>
                                                <option disabled value="">- Selecciona un motiu -</option>
                                                {motiusAll.map(motiu => {
                                                    return <option key={motiu.id} value={motiu.id}>{motiu.name}</option>
                                                })}
                                            </select>
                                            <div className={
                                                hasError("motius") ? "invalid-feedback" : "d-none"
                                            }>
                                                {errors['motius']}
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            <div className="form-group">
                                <div className="form-check">
                                    <input id="accept-rules" className="form-check-input" type="checkbox" name="acceptRules" required={true} checked={inputState.acceptRules} onChange={onInputChange}/>
                                    <label className="form-check-label" htmlFor="accept-rules">Accept
                                        l'<a href="https://www.mobilitatmanacor.cat/wp-content/uploads/2021/03/503-1.pdf" target="_blank">ordenança
                                            reguladora sobre l'ús de les vies ubicades dins la ZPR</a>
                                        <sup>*</sup></label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-check">
                                    <input id="accept-lopd" className="form-check-input" name="acceptLopd" type="checkbox" required={true} checked={inputState.acceptLopd} onChange={onInputChange}/>
                                    <label className="form-check-label" htmlFor="accept-lopd">Consent l'ús de les meves
                                        dades per a les finalitats indicades a
                                        la <a href="https://www.mobilitatmanacor.cat/proteccio-de-dades/" target="_blank">Politica
                                            de privacitat</a> <sup>*</sup></label>
                                </div>
                            </div>
                            <div className="form-group">
                                <a href="/" className="btn btn-outline-secondary mr-2 btn-block-sm mb-2 mb-lg-0">Cancel·la</a>
                                <button className="btn btn-primary btn-block-sm" onClick={handleSubmit} disabled={canGenerateAcces()}>
                                    {[2, 3, 5].includes(tipus) ? "Demana autorització" : "Genera autorització"}
                                </button>
                            </div>

                        </div>
                    )}

                    {!IsTipusActive() && (
                        <div>
                            <AccesTipusDocs/>
                            <div className="alert alert-info">
                                <p>Podeu aportar la documentació requerida per qualsevol d’aquests mitjans:</p>
                                <ul>
                                    <li>Telemàticament, enviant la sol·licitud a través de la seu electrònica de
                                        l’Ajuntament de
                                        Manacor en el següent <a href="https://manacor.eadministracio.cat/?x=Qs8EtyPkNDTgK3WJrztxUWa9Xr7-xiGeEgmLKigGn6ZWRwNl5zMDwwrOjbx7MkrIAKfzxWUgwk4GDo7oN*fZ2omWpI522bwolgYhaujpvj23-54-o54i0JmV9aFpDyj6GEtm*GlQQlmLtjb0o4VgpxOmevwesX3EShrIN7UVdZX1QaxdgR6wGe7nu2gqZ3M8rZ5FnsiHzn7Mt4pXg5z4PA" target="_blank">enllaç</a>
                                    </li>
                                    <li>Al Registre General d’Entrada de l’Ajuntament (excepte persones jurídiques)</li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {generated && (
                <Confirm response={postResponse}/>
            )}
            {!generated && (
                <div className="col-md-4">
                    <div className="acces-screen d-none d-md-block">
                        <AccesTipusImage/>
                    </div>
                    {IsTipusActive() && <AccesTipusDocs/>}
                </div>
            )}
            {criticalError && (
                <div className="col">
                    <div className="alert alert-danger">
                        Hem trobat un error quan hem generat la teva autorització. Prova una altra vegada o posa't en
                        contacte
                        amb
                        nosaltres <a href="https://www.mobilitatmanacor.cat/formulari-de-contacte/" target="_blank">aqui</a>.
                    </div>
                </div>
            )}
        </div>
    );

}

export default AccesForm;
